'use strict';var _createClass=function(){function defineProperties(target,props){for(var i=0;i<props.length;i++){var descriptor=props[i];descriptor.enumerable=descriptor.enumerable||false;descriptor.configurable=true;if("value" in descriptor)descriptor.writable=true;Object.defineProperty(target,descriptor.key,descriptor);}}return function(Constructor,protoProps,staticProps){if(protoProps)defineProperties(Constructor.prototype,protoProps);if(staticProps)defineProperties(Constructor,staticProps);return Constructor;};}();function _classCallCheck(instance,Constructor){if(!(instance instanceof Constructor)){throw new TypeError("Cannot call a class as a function");}}!function($){ /**
 * ResponsiveMenu module.
 * @module foundation.responsiveMenu
 * @requires foundation.util.triggers
 * @requires foundation.util.mediaQuery
 * @requires foundation.util.accordionMenu
 * @requires foundation.util.drilldown
 * @requires foundation.util.dropdown-menu
 */var ResponsiveMenu=function(){ /**
   * Creates a new instance of a responsive menu.
   * @class
   * @fires ResponsiveMenu#init
   * @param {jQuery} element - jQuery object to make into a dropdown menu.
   * @param {Object} options - Overrides to the default plugin settings.
   */function ResponsiveMenu(element,options){_classCallCheck(this,ResponsiveMenu);this.$element=$(element);this.rules=this.$element.data('responsive-menu');this.currentMq=null;this.currentPlugin=null;this._init();this._events();Foundation.registerPlugin(this,'ResponsiveMenu');} /**
   * Initializes the Menu by parsing the classes from the 'data-ResponsiveMenu' attribute on the element.
   * @function
   * @private
   */_createClass(ResponsiveMenu,[{key:'_init',value:function _init(){ // The first time an Interchange plugin is initialized, this.rules is converted from a string of "classes" to an object of rules
if(typeof this.rules==='string'){var rulesTree={}; // Parse rules from "classes" pulled from data attribute
var rules=this.rules.split(' '); // Iterate through every rule found
for(var i=0;i<rules.length;i++){var rule=rules[i].split('-');var ruleSize=rule.length>1?rule[0]:'small';var rulePlugin=rule.length>1?rule[1]:rule[0];if(MenuPlugins[rulePlugin]!==null){rulesTree[ruleSize]=MenuPlugins[rulePlugin];}}this.rules=rulesTree;}if(!$.isEmptyObject(this.rules)){this._checkMediaQueries();}} /**
   * Initializes events for the Menu.
   * @function
   * @private
   */},{key:'_events',value:function _events(){var _this=this;$(window).on('changed.zf.mediaquery',function(){_this._checkMediaQueries();}); // $(window).on('resize.zf.ResponsiveMenu', function() {
//   _this._checkMediaQueries();
// });
} /**
   * Checks the current screen width against available media queries. If the media query has changed, and the plugin needed has changed, the plugins will swap out.
   * @function
   * @private
   */},{key:'_checkMediaQueries',value:function _checkMediaQueries(){var matchedMq,_this=this; // Iterate through each rule and find the last matching rule
$.each(this.rules,function(key){if(Foundation.MediaQuery.atLeast(key)){matchedMq=key;}}); // No match? No dice
if(!matchedMq)return; // Plugin already initialized? We good
if(this.currentPlugin instanceof this.rules[matchedMq].plugin)return; // Remove existing plugin-specific CSS classes
$.each(MenuPlugins,function(key,value){_this.$element.removeClass(value.cssClass);}); // Add the CSS class for the new plugin
this.$element.addClass(this.rules[matchedMq].cssClass); // Create an instance of the new plugin
if(this.currentPlugin)this.currentPlugin.destroy();this.currentPlugin=new this.rules[matchedMq].plugin(this.$element,{});} /**
   * Destroys the instance of the current plugin on this element, as well as the window resize handler that switches the plugins out.
   * @function
   */},{key:'destroy',value:function destroy(){this.currentPlugin.destroy();$(window).off('.zf.ResponsiveMenu');Foundation.unregisterPlugin(this);}}]);return ResponsiveMenu;}();ResponsiveMenu.defaults={}; // The plugin matches the plugin classes with these plugin instances.
var MenuPlugins={dropdown:{cssClass:'dropdown',plugin:Foundation._plugins['dropdown-menu']||null},drilldown:{cssClass:'drilldown',plugin:Foundation._plugins['drilldown']||null},accordion:{cssClass:'accordion-menu',plugin:Foundation._plugins['accordion-menu']||null}}; // Window exports
Foundation.plugin(ResponsiveMenu,'ResponsiveMenu');}(jQuery);